<template>
  <div class="ui-card-editor">
    <div
      class="ui-card-header"
      :style="style.header"
    >
      <div
        class="ui-card-tint"
        :style="style.tint"
      ></div>

      <ui-icon
        class="cover-picker-trigger ui-clickable"
        value="mdi:image-plus"
        @click="$refs.coverUploader.open()"
      ></ui-icon>

      <file-uploader
        class="cover-uploader"
        ref="coverUploader"
        :path="path"
        max-files="1"
        accepted-files="image/*"
        @success="onCoverUploaderSuccess"
      >
        <span></span>
      </file-uploader>

      <div class="ui-card-header-texts">
        <h1>
          <input
            type="text"
            :placeholder="$t('UiCardEditor.title')"
            v-model="card.text"
            @input="save()"
          />
        </h1>

        <h2>
          <input
            type="text"
            :placeholder="$t('UiCardEditor.subtitle')"
            v-model="card.secondary"
            @input="save()"
          />
        </h2>
      </div>
    </div>

    <ui-field
      class="--wide"
      :label="$t('UiCardEditor.imagePosition')"
    >
      <div class="cover-offset">
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          v-model="card.coverOffset"
          @input="save()"
        />
      </div>
    </ui-field>

    <ui-field
      class="--wide"
      :label="$t('UiCardEditor.color')"
    >
      <ui-color-picker
        :value="sanitizedColor"
        @input="card.color = $event; save()"
      ></ui-color-picker>
    </ui-field>

    <ui-field
      class="--wide"
      :label="$t('UiCardEditor.opacity')"
    >
      <div class="opacity-range">
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          v-model="card.tintOpacity"
          @input="save()"
        />
        <span class="ui-label">{{ Math.round(card.tintOpacity * 100) }} %</span>
      </div>
    </ui-field>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n';
import { UiIcon, UiField, UiColorPicker } from '@/modules/ui/components';
import FileUploader from '@/modules/filesystem/components/File/FileUploader.vue';

export default {
  name: 'ui-card',
  mixins: [useI18n],

  components: {
    UiIcon,
    UiField,
    UiColorPicker,
    FileUploader
  },

  props: {
    path: {
      type: String,
      required: true
    },

    value: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      card: null
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.card = Object.assign(
          {
            cover: null,
            color: null,
            text: null,
            secondary: null,
            tintOpacity: 0.7,
            coverOffset: 0
          },
          newValue
        );
      }
    }
  },

  computed: {
    style() {
      return {
        header: {
          backgroundImage: this.card.cover ? `url(${this.card.cover})` : null,
          backgroundPositionY: this.card.coverOffset + '%'
        },

        tint: {
          backgroundColor: this.card.color
            ? this.card.color
            : 'var(--ui-color-primary)',
          opacity: this.card.tintOpacity
        }
      };
    },

    sanitizedColor() {
      return this.card.color
        ? this.card.color
        : window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--ui-color-primary');
    }
  },

  methods: {
    save() {
      this.$emit('input', JSON.parse(JSON.stringify(this.card)));
    },

    cancel() {
      this.card = JSON.parse(JSON.stringify(this.value));
    },

    onCoverUploaderSuccess(files) {
      if (files.length && files[0].preview) {
        this.card.cover = files[0].preview;
        this.save();
      }
    }
  },

  i18n: {
    es: {
      'UiCardEditor.title': 'Título',
      'UiCardEditor.subtitle': 'Subtítulo',
      'UiCardEditor.imagePosition': 'Posición de imagen',
      'UiCardEditor.color': 'Color',
      'UiCardEditor.opacity': 'Opacidad'
    },

    de: {
      'UiCardEditor.title': 'Untertitel',
      'UiCardEditor.subtitle': 'Subtítulo',
      'UiCardEditor.imagePosition': 'Bildstellung',
      'UiCardEditor.color': 'Farbe',
      'UiCardEditor.opacity': 'Opazität'
    },
  },

};
</script>

<style lang="scss">
.ui-card-editor {
  .ui-card-header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: 128px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: cover;

    .ui-card-tint {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .ui-card-header-texts {
      position: relative;
      padding: var(--ui-padding);
      color: #fff;

      h1 {
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
      }

      h2 {
        font-size: 1.1em;
      }

      input {
        font: inherit;
        text-shadow: inherit;
        color: #fff;
        border: 0;
        background: transparent;
        width: 100%;

        &::placeholder,
        &::-webkit-input-placeholder,
        &:-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.6);
          text-shadow: none;
        }
      }
    }

    .cover-picker-trigger {
      position: absolute;
      z-index: 3;
      top: 50px;
      left: 50%;
      padding: var(--ui-padding);
      color: #fff;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    .cover-uploader {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .opacity-range {
    display: flex;
    align-items: center;
  }
}
</style>